<template>
  <b-form novalidate>
    <b-form-group label="CPF" label-for="document">
      <b-input-group>
        <b-input
          id="document"
          v-model="form.document"
          type="search"
          placeholder="Informe CPF do cliente"
          :state="validation.document"
        />
        <b-input-group-append>
          <b-button
            variant="primary"
            :disabled="!form.document"
            @click="onSearch"
          >
            <span class="fas fa-search mr-1" /> Pesquisar
          </b-button>
        </b-input-group-append>
        <b-form-invalid-feedback v-if="$v.form.document.$invalid">
          <span v-if="!$v.form.document.required" class="d-block">
            Informe o cliente
          </span>
          <span v-if="!$v.form.document.isValidCPF" class="d-block">
            Informe um CPF válido
          </span>
        </b-form-invalid-feedback>
      </b-input-group>
      <div>
        <small v-if="showSearchHint" class="text-danger">
          Clique em Pesquisar para buscar os dados do cliente
        </small>
      </div>
    </b-form-group>

    <fieldset v-if="showCustomerForm">
      <b-form-group label-for="name">
        <template #label>
          <div class="d-flex justify-content-between">
            <span> Nome </span>
            <b-checkbox v-if="form.id" v-model="form.updateCustomer">
              Atualizar dados
            </b-checkbox>
          </div>
        </template>
        <b-form-input
          id="name"
          ref="name"
          v-model="form.name"
          :state="validation.name"
          :disabled="!form.updateCustomer"
        />
        <b-form-invalid-feedback v-if="$v.form.name.$invalid">
          Informe o nome
        </b-form-invalid-feedback>
      </b-form-group>

      <b-row>
        <b-col>
          <b-form-group label="Telefone" label-for="phone">
            <b-form-input
              id="phone"
              v-model="form.phone"
              :disabled="!form.updateCustomer"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="E-mail" label-for="email">
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              :disabled="!form.updateCustomer"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </fieldset>

    <b-form-group label="Período" label-for="period-id">
      <PeriodSelect v-model="form.periodId" :validation="$v.form.periodId" />
      <b-form-invalid-feedback v-if="$v.form.periodId.$invalid">
        Informe o período
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { isValidCPF } from '@/helpers/validators'
import { required } from 'vuelidate/lib/validators'
import withFormValidation from '@/mixins/withFormValidation'
import PeriodSelect from '@/components/Periods/PeriodSelect'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'

export default {
  name: 'ToyRentStartModalForm',
  components: {
    PeriodSelect
  },
  mixins: [
    withFormValidation({ fields: ['document', 'name', 'periodId'] }),
    withAsyncAction({
      key: 'customer',
      fetcher: {
        methodName: 'fetchCustomerByDocument',
        handler: services.customers.fetchCustomerByDocument
      }
    })
  ],
  validations: {
    form: {
      document: { required, isValidCPF },
      name: { required },
      periodId: { required }
    }
  },
  props: {
    toy: {
      type: Object,
      required: true
    },
    submitted: {
      type: Boolean
    }
  },
  data() {
    return {
      showCustomerForm: false,
      dirty: false
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showSearchHint() {
      const { $dirty, $invalid } = this.$v.form

      return $dirty && !$invalid && !this.showCustomerForm
    }
  },
  mounted() {
    if (this.form.document) {
      this.onSearch()
    }
  },
  methods: {
    async onSearch() {
      this.$v.form.document.$touch()

      if (!this.$v.form.document.$invalid) {
        try {
          const customer = await this.fetchCustomerByDocument(
            this.form.document
          )
          this.updateForm(customer)
        } catch (error) {
          if (error?.response?.status === 400) {
            this.form.updateCustomer = true
            setTimeout(() => this.$refs.name.$el.focus(), 100)
            this.$root.$toast.warning(
              'Cliente não encontrado. Preencha os dados para criar um novo cliente'
            )
            this.updateForm()
          }
        } finally {
          this.showCustomerForm = true
        }
      }
    },
    updateForm(customer) {
      this.form = {
        ...this.form,
        id: customer?.id ?? '',
        name: customer?.name ?? '',
        phone: customer?.phone ?? '',
        email: customer?.email ?? ''
      }
    }
  }
}
</script>
