<template>
  <b-modal
    v-model="open"
    :title="title"
    @ok.prevent="onOk"
    @cancel="onCancel"
    @close="onCancel"
  >
    <component
      :is="component"
      ref="form"
      v-model="form"
      :toy="toy"
      :products-amount="productsAmount"
      :rent-amount="rentAmount"
      @submit="onSubmit"
    />

    <template #footer>
      <div class="text-right">
        <b-button variant="secondary">Cancelar</b-button>
        <b-button type="submit" variant="primary" :disable="disabled">
          Ok
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import partials from './partials'
import { RENT_ACTIONS_TITLES } from '@/constants'

export default {
  name: 'ToyRentModal',
  props: {
    value: {
      type: Boolean
    },
    toy: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    initialState: {
      type: Function,
      default: () => ({})
    },
    rentAmount: {
      type: Number,
      default: 0
    },
    productsAmount: {
      type: Number,
      default: 0
    },
    prefilledClient: {
      type: Object,
      default: null
    }
  },
  data() {
    let baseForm = this.initialState() || {}
    if (this.prefilledClient) {
      baseForm.document = this.prefilledClient.document || ''
      baseForm.name = this.prefilledClient.name || ''
      baseForm.phone = this.prefilledClient.phone || ''
      baseForm.email = this.prefilledClient.email || ''
    }
    return {
      form: baseForm,
      disabled: false
    }
  },
  computed: {
    open: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    component() {
      return partials[this.action]
    },
    title() {
      return `${RENT_ACTIONS_TITLES[this.action]} Aluguel - ${this.toy.name}`
    }
  },
  methods: {
    onOk() {
      this.$refs.form.handleSubmit()
    },
    onCancel() {
      this.form = this.initialState()
      this.$emit('cancel')
    },
    onSubmit(form) {
      const { toy } = this
      this.$emit('ok', { toy, form })
    }
  }
}
</script>
